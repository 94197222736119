import $ from '../../../node_modules/jquery/dist/jquery.js';
var story;
(function (story) {
    var Main = /** @class */ (function () {
        function Main() {
            this.init();
        }
        Main.prototype.init = function () { };
        Main.prototype.load = function () {
            setTimeout(function () {
                $('h1').addClass('show');
                setTimeout(function () {
                    $('.footer').delay(500).addClass('show');
                }, 500);
            }, 500);
            $('a').on('mouseover', function (event) {
                $(event.currentTarget)
                    .css({ 'opacity': 0.25 })
                    .animate({ 'opacity': 1 }, 500, 'linear');
            });
        };
        return Main;
    }());
    story.Main = Main;
})(story || (story = {}));
(function () {
    var _story;
    document.addEventListener("DOMContentLoaded", function (event) {
        _story = new story.Main();
        var win = window;
        win.aoyagi = _story;
    });
    window.onload = function () {
        _story.load();
    };
})();
